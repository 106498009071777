import 'regenerator-runtime/runtime'

import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://mbssofjqkiwflgzkzgoz.supabase.co'
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1ic3NvZmpxa2l3Zmxnemt6Z296Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM0NDY2NTksImV4cCI6MTk4OTAyMjY1OX0.WwZZcqfsSJUUlm28CFXj7HyCDPkngDB2_kG94rJylrY"
const supabase = createClient(supabaseUrl, supabaseKey, {
    realtime: {
      params: {
        eventsPerSecond: 10
      }
    }
  }
)
const moveEvent = 'MOVE'
const channelName = 'homepage'
let boxElements = document.querySelectorAll('.dr');
let container = document.getElementById('container');
let initialItems = [...boxElements].map((it)=>[it.id,{x: it.offsetLeft, y: it.offsetTop}])

const channel = supabase.channel('homepage')

let sub = supabase
  .channel(channelName)
  .on('broadcast', { event: moveEvent }, (payload) => {
        mouseMoveFromChannel(payload.payload)
  })
  .subscribe((status) => {
    if (status === 'SUBSCRIBED') {
    }
  })

async function run(){
  console.warn("Hey there!")
  console.warn("Not much to see here - basic js and html with absolute positioning.")
  console.log("But nice of you to check <3")
  for (let i = 0; i < boxElements.length; i++) {
    boxElements[i].addEventListener("mousedown", function (event) {
      let box = event.target;
      let initialX = event.clientX - box.offsetLeft;
      let initialY = event.clientY - box.offsetTop;

      box.style.position = "absolute";
      document.addEventListener('mousemove', mouseMove);
      document.addEventListener('touchmove',mouseMove)
      document.addEventListener('touchend',mouseUp)
      document.addEventListener('mouseup', mouseUp);

      async function mouseMove(event) {
        var clickedElem = event.target;
        if(!clickedElem.classList.contains("dr")){
          clickedElem.parentElement.setAttribute("draggable",false);
        }

        let doc = document.getElementById(event.target.id)
        let left = event.clientX - initialX;
        let top = event.clientY - initialY;
        box.style.left = left + 'px';
        box.style.top = top + 'px';
        if(doc.className.includes('gradient')){
          box.style.backgroundPosition = left + 'px,' + top + 'px';
        }

        let right = left + box.offsetWidth;
        let bottom = top + box.offsetHeight;
        if (right > container.offsetWidth) {
          container.style.width = right + "px";
        }
        if (bottom > container.offsetHeight) {
          container.style.height = bottom + "px";
        }

      }


      async function mouseUp(event)  {
        let doc = document.getElementById(event.target.id)

        await sub.send({
        type: 'broadcast',
        event: moveEvent,
          payload: {
          id: doc.id,
          x: doc.offsetLeft,
            y: doc.offsetTop
        }
      })
        document.removeEventListener('mousemove', mouseMove);
        document.removeEventListener('mouseup', mouseUp);
      }
    });
  }
  }
run()




function mouseMoveFromChannel(value) {
  let box = document.getElementById(value.id);
  box.classList.add('moveTransition')

  let initialX = initialItems.find((it)=>it.id===value.id);
  if(value.y===undefined || (box.offsetLeft === value.x && box.offsetTop === value.y))
    return;
  else {
    let initialY = value.clientY - box.offsetTop;
    let left = value.x;
    let top = value.y;
        box.style.left = left + 'px';
        box.style.top = top + 'px';
        box.offsetHeight;
        let right = left + box.offsetWidth;
        let bottom = top + box.offsetHeight;
        if (right > container.offsetWidth) {
          container.style.width = right + "px";
        }
        if (bottom > container.offsetHeight) {
          container.style.height = bottom + "px";
        }
      }
  setInterval(()=>{
  box.classList.remove('moveTransition')
  },700)

}

function animate({timing, draw, duration}) {

  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    let progress = timing(timeFraction)

    draw(progress); // draw it

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }

  });
}

